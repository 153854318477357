import { Money, Sell, ShieldOutlined, Star, SupportAgent, ThumbUp } from "@mui/icons-material";
import { Box, Button, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";
import { Transition } from 'react-transition-group';
import Quiz from "../Quiz";
import { useEffect, useState } from "react";
import contentData from '../content.json';

function Home() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        document.title = contentData.title;
    });

    return (
        <>
            <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ backgroundColor: 'red', p: 2 }}>
                    <Typography level="h2" sx={{ color: 'white' }}>Новый {contentData.title.toLowerCase()} сегодня, оплата - потом!</Typography>
                </Box>
                <Box sx={{ backgroundColor: 'rgb(240, 240, 240)', p: 2, textAlign: 'center' }}>
                    <Typography level="title-lg" sx={{ color: 'black' }}>От {contentData.minPrice} рублей в месяц! В наличии в Беларуси</Typography>
                </Box>
                <img style={{ width: '100%', borderRadius: '0 0 8px 8px' }} src="./images/header.jpg" />
                <Typography sx={{ mt: 2, mb: 2 }} level="h5">Жмите кнопку "Хочу {contentData.title}" 👇</Typography>

                <Box sx={{ backgroundColor: 'rgb(240, 240, 240)', p: 2 }}>
                    <Typography level="h2">Почему мы?</Typography>
                    <Stack sx={{ textAlign: 'left', mt: 2 }} direction='row'>
                        <Box sx={{ mr: 3 }}>
                            <ShieldOutlined sx={{ fontSize: 64, color: 'gray' }} />
                        </Box>
                        <Stack direction='column'>
                            <Typography sx={{ fontWeight: 600 }} level="title-lg">Безопасность</Typography>
                            <Typography sx={{ mt: 1 }} level="body-md">Мы работаем напрямую с банком и при покупке вы получаете все необходимые документы</Typography>
                        </Stack>
                    </Stack>

                    <Stack sx={{ textAlign: 'left', mt: 2 }} direction='row'>
                        <Box sx={{ mr: 3 }}>
                            <Sell sx={{ fontSize: 64, color: 'gray' }} />
                        </Box>
                        <Stack direction='column'>
                            <Typography sx={{ fontWeight: 600 }} level="title-lg">Выгода</Typography>
                            <Typography sx={{ mt: 1 }} level="body-md">У нас самые низкие цены, тк мы являемся одним из крупнейших поставщиков РБ и осуществляем продажи напрямую, без посредников</Typography>
                        </Stack>
                    </Stack>

                    <Stack sx={{ textAlign: 'left', mt: 2 }} direction='row'>
                        <Box sx={{ mr: 3 }}>
                            <Star sx={{ fontSize: 64, color: 'gray' }} />
                        </Box>
                        <Stack direction='column'>
                            <Typography sx={{ fontWeight: 600 }} level="title-lg">Лучшие условия</Typography>
                            <Typography sx={{ mt: 1 }} level="body-md">Мы оформляем рассрочку до 5 лет без первого взноса, а значит первый платеж вы внесете только через месяц после покупки</Typography>
                        </Stack>
                    </Stack>

                    <Stack sx={{ textAlign: 'left', mt: 2 }} direction='row'>
                        <Box sx={{ mr: 3 }}>
                            <ThumbUp sx={{ fontSize: 64, color: 'gray' }} />
                        </Box>
                        <Stack direction='column'>
                            <Typography sx={{ fontWeight: 600 }} level="title-lg">Простота в оформлении</Typography>
                            <Typography sx={{ mt: 1 }} level="body-md">Вам не нужны никакие справки и долгие ожидания одобрения рассрочки, оформляем за 1 день</Typography>
                        </Stack>
                    </Stack>

                    <Stack sx={{ textAlign: 'left', mt: 2 }} direction='row'>
                        <Box sx={{ mr: 3 }}>
                            <SupportAgent sx={{ fontSize: 64, color: 'gray' }} />
                        </Box>
                        <Stack direction='column'>
                            <Typography sx={{ fontWeight: 600 }} level="title-lg">Бесплатная консультация</Typography>
                            <Typography sx={{ mt: 1 }} level="body-md">Мы уделим вам столько времени, сколько нужно, поможем определиться с моделью и ответим на любые вопросы</Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{ backgroundColor: 'rgba(34,34,34,.1)', p: 2, pb: 12 }}>
                    <Typography sx={{ mb: 2 }}>Время работы: пн-вс 09:00 - 21:00</Typography>
                    <Typography>ООО "Цитруском" УНП 193699866</Typography>
                    <Typography sx={{ mb: 2 }}>220046, г. Минск, а/я 46</Typography>
                    <Typography level="title-md">Акция действует на всей территории Беларуси</Typography>
                    <Typography>Телефон: +375 (29) 330-44-93</Typography>
                    <Typography>E-mail: citrustele@mail.ru</Typography>
                </Box>
            </Box>

            <Button onClick={() => setOpen(true)} size="lg" sx={{ borderRadius: '250px', width: '70%', maxWidth: '400px', px: 6, py: 2, position: 'fixed', bottom: 14, textWrap: 'nowrap', left: '50%', transform: 'translateX(-50%)', fontSize: 20 }}>Хочу {contentData.title}</Button>

            <Transition in={open} timeout={400}>
                {(state) => (
                    <Modal
                        keepMounted
                        open={!['exited', 'exiting'].includes(state)}
                        onClose={() => setOpen(false)}
                        slotProps={{
                            backdrop: {
                                sx: {
                                    opacity: 0,
                                    backdropFilter: 'none',
                                    transition: `opacity 400ms, backdrop-filter 400ms`,
                                    ...{
                                        entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                        entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    }[state],
                                },
                            },
                        }}
                        sx={{
                            visibility: state === 'exited' ? 'hidden' : 'visible',
                        }}
                    >
                        <ModalDialog
                            layout="fullscreen"
                            sx={{
                                opacity: 0,
                                transition: `opacity 300ms`,
                                ...{
                                    entering: { opacity: 1 },
                                    entered: { opacity: 1 },
                                }[state],
                            }}
                        >
                            <ModalClose sx={{ zIndex: 9999 }} />
                            <DialogContent sx={{ height: '100%' }}>
                                <Quiz questions={contentData.questions} />
                            </DialogContent>
                        </ModalDialog>
                    </Modal>
                )}
            </Transition>
        </>
    );
}

export default Home;
